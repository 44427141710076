import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Hero = ({ title, backgroundImage, bgPos }) => {
	const pluginImage = getImage(backgroundImage);
	const bgPosition = bgPos || "top";
	return (
		<BgImage
			style={{ backgroundPosition: bgPosition }}
			Tag="section"
			className="w-100 position-relative hero-image"
			id="hero-home"
			image={pluginImage}
		>
			<div
				style={{ index: 1, opacity: 0.4, backgroundColor: "black" }}
				className="w-100 h-100 start-0 top-0 position-absolute"
			></div>
			<Container style={{ index: 2 }} className="h-100 position-relative">
				<Row className="align-items-center ps-lg-10  h-100">
					<Col xs={12}>
						<h1 className="text-white big-text text-center text-lg-start gobold-reg ">
							{title}
						</h1>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default Hero;
